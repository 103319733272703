@import "./theme";
@import "./material-custom";
@import "./material-no-anim";
@import "./material-branding.scss";
@import "./datepicker";
@import "./bugfix";
@import "./variables";
@import "./skeleton";
@import "./branding.scss";

html,
body {
  height: 100%;
  background-color: #edeef2;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input {
  font-family: inherit;
}

input.cdk-text-field-autofilled {
  @include cdk-text-field-autofill-color(transparent, "");
}

// Custom Classes

.none {
  display: none;
}

.hidden {
  visibility: hidden;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

.center {
  text-align: center;
  justify-content: center;
}

.right {
  text-align: right;
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;

  &.mat-button,
  &.mat-button-base {
    .mat-button-wrapper {
      display: inline-flex !important;
      text-overflow: ellipsis;
      overflow: hidden;
      align-items: center;
      justify-content: center;
      vertical-align: middle;
      width: 100%;

      > span {
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  &.mat-checkbox {
    label.mat-checkbox-layout {
      overflow: hidden;
      max-width: 100%;
    }
  }
}

// SCROLLBAR

::-webkit-scrollbar {
  width: $scrollBarSize;
  height: $scrollBarSize;
  background: #dcdde1;
  -webkit-appearance: none;
  -webkit-overflow-scrolling: auto;
}

::-webkit-scrollbar-track {
}

::-webkit-scrollbar-thumb {
  background: #a4a7b2;
}

::-webkit-scrollbar-thumb:window-inactive {
  background: #a4a7b2;
}

::-webkit-scrollbar-corner {
  background: #dcdde1;
}

::-webkit-scrollbar-track-piece {
  background: transparent;
}

// Other

shared-avatar {
  user-select: none;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: middle;
}

.checkbox-list {
  column-width: 200px;
  column-fill: balance;
  column-gap: 10px;

  mat-checkbox {
    width: 200px;
    display: inline-block;

    label {
      width: 100%;

      .mat-checkbox-label {
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 1 1 auto;
      }
    }
  }
}

button {
  .when-loading {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    visibility: hidden;
  }

  &.submit-loading {
    .unless-loading {
      visibility: hidden;
    }

    .when-loading {
      visibility: visible;
    }
  }
}

.popover-overlay {
  pointer-events: none !important;
}

a:not([href]) {
  cursor: default;
}

@for $i from 0 through 4 {
  .color-score-#{$i} {
    color: map-get($scoresMap, $i);
  }
}

@for $i from 0 through 4 {
  .bg-score-#{$i} {
    background-color: map-get($scoresMap, $i);
  }
}

@for $i from 0 through 4 {
  .checkbox-score-#{$i} {
    $color: map-get($scoresMap, $i);

    &:not(.mat-checkbox-disabled) {
      .mat-checkbox-frame {
        border-color: $color !important;
      }

      &.mat-checkbox-checked,
      &.mat-checkbox-indeterminate {
        .mat-checkbox-background {
          background-color: $color !important;
        }
      }

      &.mat-checkbox-checked,
      &:active {
        .mat-ripple-element {
          background-color: $color !important;
        }
      }
    }
  }
}

.rating-select-panel {
  margin-top: 24px;
}

// Virtual scroll rerender hack - https://stackoverflow.com/a/24095217/2346893

cdk-virtual-scroll-viewport {
  animation: normal cdkVirtualScrollStart;
}

@keyframes cdkVirtualScrollStart {
  from {
    outline-color: transparent;
  }
  to {
    outline-color: transparent;
  }
}

body:not(.pdf-body) {
  .pdf-only {
    display: none;
  }
}

.html2pdf__overlay {
  pointer-events: none;
}

// Only show the first of consecutive banners

shared-top-banner,
.app-banner {
  &:not(.app-banner-hidden) + {
    shared-top-banner,
    .app-banner {
      &:not(.app-banner-always-show) {
        display: none;
      }
    }
  }
}
.cdk-overlay-container .cdk-overlay-pane .testClass{
  margin-left: 20%;
  width: 200px;
  min-width: 100% !important;
  margin: 0 auto;
}

.full-ccoffer-modal {
  box-shadow: 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12), 0px 10px 8px -5px rgba(0, 0, 0, 0.2);
  overflow: auto;
  .mat-form-field-infix {
    padding: 0.5em 0;
    border-top: 0.4em solid transparent;
    color: #EDEEF2;
  }
  .mat-dialog-container {
    width:800px;
    height: fit-content;
    overflow: auto;
    border-radius:8px;
  }
  .mat-dialog-content {
    max-height: initial;
  }
  @media (min-width: 320px) and (max-width: 480px) {
    .full-ccoffer-modal {
      height: 100vh;
    }
    .mat-dialog-container {
      width: 100%;
      height: 100vh;
      min-height: 100%;
      border-radius:0;
      overflow-y: visible !important;
    }
  }
}

.cdk-global-scrollblock {
  overflow-y: auto !important;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.60);
  backdrop-filter: blur(10px);
}

#hubspot-messages-iframe-container {
  z-index: 100 !important;
}
