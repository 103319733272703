@import "@angular/material/theming";
@import "~include-media/dist/_include-media";
@import "./helpers.scss";

$breakpoints: (
  xxs: 480px,
  xs: 600px,
  sm: 960px,
  md: 1280px,
  lg: 1920px,
  xl: 2560px,
  xxl: 3840px,
  // Height values
  hxs: 640px,
  hsm: 720px,
  hmd: 960px,
  hlg: 1080px,
);

$media-expressions: (
  "screen": "screen",
  "print": "print",
  "handheld": "handheld",
  "landscape": "(orientation: landscape)",
  "portrait": "(orientation: portrait)",
  "retina2x": "(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)",
  "retina3x": "(-webkit-min-device-pixel-ratio: 3), (min-resolution: 350dpi)",
  "handset": "(max-width: 599.99px) and (orientation: portrait), (max-width: 959.99px) and (orientation: landscape)",
  "desktop": "(min-width: 599.99px) and (orientation: portrait), (min-width: 959.99px) and (orientation: landscape)",
);

// Palettes generated using http://mcg.mbitson.com/
$app-primary-palette-map: (
  50: #e5f8ed,
  100: #beedd3,
  200: #93e1b6,
  300: #68d498,
  400: #47cb82,
  500: #27c26c,
  600: #23bc64,
  700: #1db459,
  800: #17ac4f,
  900: #0e9f3d,
  A100: #ceffdc,
  A200: #9bffb7,
  A400: #68ff92,
  A700: #4eff80,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
$app-secondary-palette-map: (
  50: #fdeae5,
  100: #f9cabf,
  200: #f5a794,
  300: #f18369,
  400: #ee6949,
  500: #eb4e29,
  600: #e94724,
  700: #e53d1f,
  800: #e23519,
  900: #dd250f,
  A100: #ffffff,
  A200: #ffdad6,
  A400: #ffaba3,
  A700: #ff938a,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
$primary-palette: mat-palette($app-primary-palette-map, 500);
$accent-palette: mat-palette($app-secondary-palette-map, 500);
$theme: mat-light-theme($primary-palette, $accent-palette);
$primary: mat-color($primary-palette);
$accent: mat-color($accent-palette);
$warn: #f7b82f;

$computerTimeColor: #27c26c;
$manualTimeColor: #f7b82f;
$mobileTimeColor: #3c4ad9;
$neutralColor: #585a6e;
$unratedColor: #a4a7b2;
$offlineColor: #a4a7b2;
$errorColor: #f22f42;

$tableBorderColor: #edeef2;
$skeletonColor: #dcdde1;
$sliderTrackColor: #ccccd0;
$inputHintColor: $unratedColor;

$scoresMap: (
  0: $unratedColor,
  1: $unratedColor,
  2: $errorColor,
  3: $neutralColor,
  4: $primary,
);

$pdfBorderColor: #efefef;

$formFieldVerticalMargin: 24px;

$scrollBarSize: 8px;
