.full-cover {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  box-sizing: border-box;
}

@mixin screencastCard(
  $width,
  $height,
  $margin,
  $extraHeight: 62px,
  $multipleSizes: true,
  $tiled: false,
  $variable: "--screencast-scale"
) {
  $var: var(#{$variable}, 1);
  $calculatedHeight: calc(#{$var} * #{$height} + #{$extraHeight});

  app-screencast-card {
    margin: $margin;
    width: calc(#{$var} * #{$width});
    height: $calculatedHeight;

    @if $multipleSizes {
      &.screen-count-2,
      &.screen-count-gt-2 {
        width: calc(#{$var} * #{(2 * $width) + (2 * $margin)});
      }

      &.screen-count-gt-2 {
        @if $tiled {
          height: auto;
          min-height: $calculatedHeight;
        }
      }
    }

    @if $tiled {
      ::ng-deep .screens-wrap {
        flex-wrap: wrap-reverse;
        white-space: normal;

        a {
          min-width: 49%;
        }
      }
    }
  }
}

@mixin neverEmpty() {
  &:empty:after {
    content: "\a0";
  }
}

@mixin virtual-scroll-table($itemHeight, $headerHeight) {
  ::ng-deep {
    .cdk-virtual-scroll-content-wrapper > div {
      height: $itemHeight;
    }
  }

  mat-table {
    position: absolute;
    top: 0;
    display: inline-flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;

    mat-header-row {
      height: $headerHeight;
      min-height: $headerHeight;
      white-space: nowrap;
      border-bottom-width: 0;

      mat-header-cell {
        height: $headerHeight;
        position: relative;
        background: white;
        border-bottom-style: solid;
        border-bottom-width: 2px;
        white-space: normal;

        &.center {
          text-align: center;
        }
      }
    }

    mat-row {
      height: $itemHeight;
      min-height: $itemHeight;
      position: relative;
      white-space: nowrap;
      border-bottom-width: 0;

      &:hover {
        @include mat-elevation(2);
        z-index: 100;
      }

      mat-cell {
        height: $itemHeight;
        border-bottom-style: solid;
        border-bottom-width: 1px;
      }
    }

    mat-header-cell,
    mat-cell {
      border-bottom-color: $tableBorderColor;
      box-sizing: border-box;
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 120px;

      &.border {
        border-right: 1px solid $tableBorderColor;
        padding: 0 16px;
      }

      &.padding {
        padding: 0 16px;
      }
    }
  }
}
